.scene-expanded-overlay {
  display: flex;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);

  .logo {
    position: absolute;
    max-height: 200px;
    margin-block-start: 0.9em;
    min-height: 65px;
    left: 60px;
    top: 40px;
    z-index: 2;
  }

  .container {
    display: flex;
    width: calc(100% - 2%);
    height: calc(100% - 2%);
    margin: 1%;
    flex-direction: row;
    z-index: 1;
    border-radius: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;

    .message-container {
      position: relative;
      z-index: 1;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      text-align: center;

      > * {
        position: relative;
        z-index: 1;
      }

      .hero-image-background {
        height: 100%;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
      }
    }

    .headline {
      font-size: 2.2em;
      font-weight: 400;
      margin-block-start: 0em;
      line-height: 1.3em;
      display: -webkit-box;
      -webkit-line-clamp: 3; 
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      margin-block-end: 2.2em;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      flex-direction: row;
      display: flex;
      justify-content: center;
      gap: 4%;

      li {
        border-radius: 40px;
        background-color: #F9F9F91A;
        -webkit-transition: all .2s;
        -webkit-transition-timing-function: ease-in-out;
        transition: all .2s;
        transition-timing-function: ease-in-out;

        &:hover {
          background-color: #F9F9F9E5;
        }

        &.focused {
          background-color: #F9F9F9E5;
        }
      }
    }

    &.center {
      justify-content: center;
    }

    .MuiSvgIcon-root {
      margin-right: 4%;
      width: 1.4em;
      height: 1.4em;
    }
  }

  .MuiButtonBase-root.MuiButton-root {
    //styleName: Button/Button - Focused;
    letter-spacing: 1.7599999904632568px;
    text-align: center;
    color: #fff;
    font-size: 1.8em;
    background: #F9F9F91A;
    padding: 10px 30px;
    width: 95%;
    font-weight: 600;
  }

  .rail {
    display: none;
    color: #fff;

    h2 {

    }
  }

  &.two-column {
    .message-container {
      width: 60%;
      padding: 5%;

      h1 {
        font-size: 3.2em;
        margin-block-end: .2em;
      }

      p {
        font-size: 1.6em;
      }
    }
    .rail {
      display: flex;
      width: 36%;
      padding: 1.5%;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      background: linear-gradient(to bottom, rgba(128, 185, 235, 0) 0%, rgba(10, 99, 165, 0.7) 100%);

      h2 {
        font-size: 2.2em;
        font-weight: 400;
        margin-block-start: 0.5em;
        margin-block-end: .5em;
      }

      .offer {
        text-transform: uppercase;
        font-size: 2.5em;
        text-align: center;
        font-weight: 800;
        color: #006FAC;
        line-height: 1.3em;
        margin-block-start: 0;
        margin-block-end: .5em;
      }
    }
  }
}
