.feature-gateway-messaging {
  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    color: #fff;
    gap: 10px;

    &.center {
      justify-content: center;
    }

    .MuiSvgIcon-root {
      margin-right: 4%;
      width: 1.4em;
      height: 1.4em;
    }

    .call-to-action {
      font-size: 1.5em;
    }

    .qr {
      margin-block-start: 1.2em;
    }

    .url {
      font-size: 1.5em;
    }

    .disclaimer {
      font-size: 1.0em;
      text-align: center;
    }
  }

  .MuiButtonBase-root.MuiButton-root {
    //styleName: Button/Button - Focused;
    letter-spacing: 1.7599999904632568px;
    text-align: center;
    color: #fff;
    font-size: 1.8em;
    background: #F9F9F91A;
    padding: 10px 30px;
    width: 95%;
    font-weight: 600;

    &.focused {
      background: #fff;
      color: #000;
    }
  }

  .MuiPaper-elevation {
    border-radius: 40px;
    font-size: 1.4em;
    padding: 15px 25px;

    .css-ki1hdl-MuiAlert-action {
      display: none;
    }
  }
}
