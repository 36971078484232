.feature-brand-logo-lockup {
  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.center {
      justify-content: center;
    }
  }
  .headline {
    font-size: 2.2em;
    font-weight: 600;
    text-align: center;
    margin-block-start: 0em;
    line-height: 1.3em;
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    margin-block-end: 1.2em;
  }
  .message {
    font-size: 1.4em;
  }
  .logo {
    max-height: 200px;
    margin-block-start: 0.9em;
    min-height: 65px;
  }

  .hero {
    max-height: 400px;
    min-height: 250px;
    padding: 5% 0;

  }
}

.feature-gateway-messaging {
  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.center {
      justify-content: center;
    }
  }

  .MuiButtonBase-root.MuiButton-root {
    color: #000;
    font-size: 1.8em;
    background: white;
    padding: 10px 30px;
    width: 95%;
    font-weight: 600;
  }
}

.feature-background {
  .gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0.87239) 6.67%, rgba(0, 0, 0, 0.848751) 13.33%, rgba(0, 0, 0, 0.808139) 20%, rgba(0, 0, 0, 0.750278) 26.67%, rgba(0, 0, 0, 0.676038) 33.33%, rgba(0, 0, 0, 0.587942) 40%, rgba(0, 0, 0, 0.490432) 46.67%, rgba(0, 0, 0, 0.389568) 53.33%, rgba(0, 0, 0, 0.292058) 60%, rgba(0, 0, 0, 0.203962) 66.67%, rgba(0, 0, 0, 0.129722) 73.33%, rgba(0, 0, 0, 0.0718607) 80%, rgba(0, 0, 0, 0.0312488) 86.67%, rgba(0, 0, 0, 0.00760953) 93.33%, rgba(0, 0, 0, 0) 100%);
  }
}

@media screen and (max-width: 991px) {
  .pause-ad {
  }
}
