.pause-ad {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.scene-expanded-overlay-container {
  height: 100%;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

@media screen and (max-width: 991px) {
  .pause-ad {
  }
}
