.scene-compact-overlay {
  height: 100%;
  width: 35%;
  color: #fff;
  right: 0;
  position: absolute;

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 5%;
    align-items: flex-start;
  
    .feature-brand-logo-lockup {
      position: relative;
      margin-top: 5%;
    }
  
    .btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      justify-content: center;
  
      .MuiSvgIcon-root {
        margin-right: 4%;
        width: 1.4em;
        height: 1.4em;
      }
    
      &.MuiButtonBase-root.MuiButton-root {
        //styleName: Button/Button - Focused;
        letter-spacing: 1.7599999904632568px;
        text-align: center;
        color: #fff;
        font-size: 1.8em;
        background: #F9F9F94D;
        padding: 10px 30px;
        width: 95%;
        font-weight: 600;

        &.primary {
          background: #fff;
          color: #000;
        }
      }
    }
  }

  .featured-background {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    height: 100%;
    width: 235%;
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.5) 35.42%, rgba(0, 0, 0, 0.25) 100%);
    background: radial-gradient(58.86% 58.86% at 50% 50%, rgba(0, 0, 0, 0.31) 81.87%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 4.23%, rgba(0, 0, 0, 0.00812836) 10.51%, rgba(0, 0, 0, 0.0333794) 16.79%, rgba(0, 0, 0, 0.0767603) 23.08%, rgba(0, 0, 0, 0.138567) 29.36%, rgba(0, 0, 0, 0.217868) 35.64%, rgba(0, 0, 0, 0.311971) 41.93%, rgba(0, 0, 0, 0.4261) 48.21%, rgba(0, 0, 0, 0.5539) 54.49%, rgba(0, 0, 0, 0.678) 60.78%, rgba(0, 0, 0, 0.7921) 67.06%, rgba(0, 0, 0, 0.8714) 73.34%, rgba(0, 0, 0, 0.9032) 79.63%, rgba(0, 0, 0, 0.9166) 85.91%, rgba(0, 0, 0, 0.93) 92.2%, rgba(0, 0, 0, 0.94) 98.48%);
  }
}